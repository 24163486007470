import logo from '../art/hero.png'


export default function Logo({size}){

    return(
        <img src={logo} width={size} height={size} />

    );

}


